<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import useGameLocation from '~/composables/GameLocation/GameLocation'
  import { ref, watch } from '#imports'
  import { GameLocationListContent } from '~/model/GameLocation/GameLocation'
  import { useLocaleStore } from '~/store/Locale'
  import { useGameContentStore } from '~/store/GameContent'
  import { GameDistrictId } from '~/model/GameDistrict/GameDistrict'
  import ResponsiveImg from '~/components/ResponsiveImg/ResponsiveImg.vue'
  import ListInteraction from '~/components/ListInteraction/ListInteraction.vue'
  import { ListInteractionFacet } from '~/components/ListInteraction/ListInteractionFacet'
  import useDebug from '~/composables/Debug/Debug'

  interface Props {
    districtId: GameDistrictId
    showNested?: boolean
  }

  const localeStore = useLocaleStore()
  const gameContentStore = useGameContentStore()

  const props = defineProps<Props>()
  const { getList, toGameLocation } = useGameLocation()
  const { isDebug } = useDebug()
  const { assignPendingData } = gameContentStore
  const { localeId } = storeToRefs(localeStore)
  const locationList = ref<Array<GameLocationListContent> | null>(null)

  watch(
    () => props.districtId,
    async (newVal) => {
      if (!newVal) {
        return
      }

      const req = await getList(localeId.value, props.districtId)
      assignPendingData<Array<GameLocationListContent>>(req, (data) => {
        locationList.value = data.value
      })
    },
    { immediate: true },
  )
</script>

<template>
  <div class="c-list-location">
    <ul class="u-reset c-list-location__list">
      <li v-for="location in locationList" :key="location.id" class="c-list-location__entry">
        <div class="c-list-location__data">
          <div class="c-list-location__image-wrap">
            <ResponsiveImg
              v-if="location.icon"
              :src="location.icon"
              :width="40"
              alt=""
              class="c-list-location__image"
            />
          </div>
          <span class="c-list-location__title u-reset">
            {{ location.name }}
            <code v-if="isDebug" class="u-debug">
              <NuxtLink :to="toGameLocation(location.id)">link</NuxtLink>
            </code>
          </span>
        </div>
        <ListInteraction
          v-if="showNested"
          :facets="[ListInteractionFacet.WithinListLocation]"
          :location-id="location.id"
        />
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/typography/typography' as type;

  $image-width: 40px;

  .c-list-location__list {
    display: grid;
    flex-flow: column nowrap;
    row-gap: 20px;
  }

  .c-list-location__entry {
    display: flex;
    flex-flow: column nowrap;
  }

  .c-list-location__data {
    display: flex;
    align-items: center;
  }

  .c-list-location__image-wrap {
    flex: 0 0 auto;
  }

  .c-list-location__image {
    width: $image-width;
  }

  .c-list-location__title {
    @include type.h4;
    margin-left: 8px;
  }
</style>
